import { IBlock } from "../../../framework/src/IBlock";
import React from "react";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import ToastNotification from "../../../components/src/ToastNotification.web";

export interface ValidResponseType {
  data: object;
  errors: Array<ErrorPayloadType>;
}
export interface ErrorPayloadType {
  key: string;
  message: string;
}
export interface ErrorResponseType {
  errors: string;
}
export interface InvalidResponseType {
  errors: Array<string>;
}

export interface KpisDKRGetData {
  id: string,
  type: string,
  attributes: {
    id: number,
    name: string,
    title: string,
    description: string,
    status: string,
    department_id: number,
    kpis: KpisDKRCard
  }
}

export interface KpiDKRData {
  data: KpisDKRGetData
}

export interface KpisDKRCard {
  Core: Array<KpisDKR>,
  Productivity: Array<KpisDKR>,
  Checklist: Array<KpisDKR>,
  Attendance: Array<KpisDKR>,
  Timing: Array<KpisDKR>,
  Occurrence: Array<KpisDKR>,
  Report: Array<KpisDKR>
}

export interface KpisDKR {
  KPI: string,
  kpi_type: string,
  weightage: number,
  goal_value: number,
  goal_type: string,
  DKR_Title: Array<string>,
  DKR_Title_value: Array<string>,
  options: Array<KpisDKROption>,
  DKR_description: null,
  DKR_response_type: Array<string | null>,
  calculation_type: string,
  minimum_qualify_value: string,
  applicable_occurrences: Array<string>,
  app_occu_count: number
}
export interface KpisDKROption {
  id: number,
  dkr_id: number,
  title: string,
  dkr_responses?: Array<KpisDKRResponses>,
}

export interface KpisDKRResponses {
  res_id: number,
  options: string
}

export interface KpiCommentData {
  commentable_type: string,
  commentable_id: number,
  dkr_input: string,
  remark: string,
  account_id: number
}

export interface SaveDkrData {
  comments: Array<SaveDkrComment>
}

export interface SaveDkrComment {
  message: string,
  input: string,
  remark: string
}
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: string
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  type: string;
  description: string;
  automatic: boolean;
  runRate: boolean;
  analytics: boolean;
  goaltype: string;
  isModal: boolean;
  statisticsTab: string;
  tabNumber: number;
  scoreTabNumber: number;
  employeesData: any;
  loading: boolean;
  open: boolean;
  name: any;
  descriptionModal: any;
  id: any;
  dayType: any;
  startDate: any;
  endDate: any;
  infoBoardData: any;
  title: any;
  descriptionModalSecond: any;
  openNew: boolean;
  filteredData: any;
  infoTitle: any;
  infoDescription: any
  analyticsChecked: boolean;
  runRateChecked:boolean;
  automaticChecked: boolean;
  isHovered: boolean;
  selectedOption: string;
  goalType: string;
  analyticsData: any;
  kpiNextNo: number;
  kpiNameUpdate: string;
  descriptions: string;
  points: string;
  goalValue: number;
  goalCalMethod: string;
  kpinameError: string;
  kpiDescrError: string;
  kpiWeightError: string;
  maxDescriptionLength: Number;
  descriptionsLenght: Number;
  isOpenDialog: boolean;
  dialogMessage: [];
  goalValError: string;
  analyticParam: string;
  kpiData: any;
  isEditkpi: boolean;
  kpisDkrData:Array<KpisDKR>;
  hasError: string;
  dkrValue: string;
  dkrSelect: string;
  dkrRemark: string;
  dkrName: string;
  dkrId: number;
  dkrCommentData: Array<KpiCommentData>
  accountId: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddKpiController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEventDetailsId: any
  getinfoboardsDetailsId: any
  getAnalyticsDetailsId: string = "";
  getTemplateDataId: string = "";
  postNewKpiId: string = "";
  getShowTempaltewithKpiId : string = "";
  getKpiDKRDataApiCallId: string | null = null;
  postKpiDKRDataApiCallId: string | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      type: "core",
      description: "",
      automatic: false,
      runRate: false,
      analytics: false,
      goaltype: "number",
      isModal: false,
      statisticsTab: '',
      tabNumber: 1,
      scoreTabNumber: 0,
      employeesData: [],
      infoBoardData: [],
      loading: true,
      open: false,
      name: "",
      descriptionModal: "",
      id: null,
      dayType: "",
      startDate: "",
      endDate: "",
      title: "",
      descriptionModalSecond: "",
      openNew: false,
      filteredData: [],
      infoTitle: '',
      infoDescription: '',
      analyticsChecked: false,
      runRateChecked:false,
      automaticChecked: false,
      isHovered: false,
      selectedOption: 'core',
      goalType: "percentage",
      analyticsData: [],
      kpiNextNo: 1,
      kpiNameUpdate: '',
      descriptions: '',
      points: '',
      goalValue: 100,
      goalCalMethod: 'sum_calculation',
      kpinameError: '',
      kpiDescrError: '',
      kpiWeightError: '',
      maxDescriptionLength: 1000,
      descriptionsLenght: 0,
      isOpenDialog: false,
      dialogMessage: [],
      goalValError: '',
      analyticParam: '3',
      kpiData: [],
      isEditkpi: false,
      kpisDkrData: [],
      hasError: "",
      dkrValue: "",
      dkrSelect: "",
      dkrRemark: "",
      dkrName: "",
      dkrId: 0,
      dkrCommentData: [],
      accountId: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {

    super.componentDidMount();
    this.getEventDetails();
    this.infoboardsDetails();


    // Customizable Area Start
    const activeTab = await getStorageData("tabb")
    if (activeTab === null) {
      this.setState({ statisticsTab: "" })
    }
    else {
      this.setState({ statisticsTab: activeTab })
    }
    this.getTemplateAddedResponse()
    const isEdit = window.location.pathname === "/EditKpi";
    const templateId = localStorage.getItem('TemplateNavigationId')
    if (isEdit){
      this.setState({isEditkpi: isEdit})
      this.showTemplateswithKpis(Number(templateId))
  }
  this.getAnalyticaldetails()
    this.handleKpiDKRData()
    const userId  = await getStorageData("userId");
    if(userId){
      this.setState({accountId : userId})
    }
    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getEventDetailsId) {
        this.getEventDetailsIdFunction(responseJson)
      }
      if (apiRequestCallId === this.getinfoboardsDetailsId) {
        this.infoBoardDataFunction(responseJson)
      }
      if (apiRequestCallId === this.getAnalyticsDetailsId) {
        this.setState({ analyticsData: responseJson.data })
      }
      if (apiRequestCallId === this.getTemplateDataId) {
        this.getTemplateAddedDetail(responseJson)
      }
      if (apiRequestCallId === this.postNewKpiId) {
        this.handleCreatekpiRes(responseJson)
      }
      if (apiRequestCallId === this.getShowTempaltewithKpiId) {
        this.setState({ kpiData: responseJson.data })
        this.handleEditkpi()
      }
      if (this.isValidResponseController(responseJson)) {
        this.apiSuccessCallBacksController(apiRequestCallId, responseJson);
      }
      if (this.isInValidResponseController(responseJson)) {
        this.apiFailureCallBacksController(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // web events
  handleChange = (event: React.ChangeEvent<{
    name?: string | undefined;
    value: string;
  }>) => {
    this.setState({
      type: event.target.value
    });
  };
  handleDescriptionChange = (event: React.ChangeEvent<{
    name?: string | undefined;
    value: string;
  }>) => {
    this.setState({ description: event.target.value })
  };
  handleAutomatic = () => {
    this.setState({ automatic: !this.state.automatic })
  }
  handleRunrate = () => {
    this.setState({ runRate: !this.state.runRate })
  }
  handleAnalytics = () => {
    this.setState({ analytics: !this.state.analytics })
  }
  handleGoal = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ goaltype: event.target.value })
  }

  // Customizable Area Start
  isValidResponseController = (responseJson: ValidResponseType) => {
    return responseJson && !responseJson.errors;
  };

  isInValidResponseController = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiSuccessCallBacksController = (apiRequestCallId: string, responseJson: KpiDKRData & SaveDkrData) => {
    if (apiRequestCallId === this.getKpiDKRDataApiCallId) {
      this.getKpiDKRDataSuccessCallBack(responseJson)
    }
    if (apiRequestCallId === this.postKpiDKRDataApiCallId) {
      this.saveKpiDKRDataSuccessCallBack(responseJson)
    }
  };

  apiFailureCallBacksController = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
    if (apiRequestCallId === this.getKpiDKRDataApiCallId) {
      this.getKpiDKRDataFailurCallBack(responseJson)
    }
    if (apiRequestCallId === this.postKpiDKRDataApiCallId) {
      this.saveKpiDKRDataFailurCallBack(responseJson)
    }
  };

  apiCallDKR = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    apiType?: string;
    type?: string;
  }) => {
    let { contentType, method, endPoint, body } = valueData;
    const token = (await getStorageData("authToken")) || "";
    let header = {
      "Content-Type": contentType,
      token,
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getEventDetailsIdFunction = (responseJson: any) => {
    if (responseJson) {
      this.setState({ employeesData: responseJson?.data, loading: false });
    }
  }
  infoBoardDataFunction = (responseJson: any) => {
    if (responseJson) {
      this.setState({ infoBoardData: responseJson?.infoboards })
    }
  }
  handlePageTabNavigate = async (pageName: any) => {
    setStorageData("tabb", pageName)
    this.setState({ statisticsTab: pageName })
  }
  handlePageTabBackNavigate = () => {
    removeStorageData("tabb")
    this.setState({ statisticsTab: "" })
  }
  handleRadioChange = (event: any) => {
    this.setState({
      selectedOption: event.target.value,
      descriptions: '',
      points: '',
      kpinameError: "",
      kpiDescrError: "",
      kpiWeightError: "",
      goalValError: "",
      automaticChecked: false,
      runRate: false,
      analyticsChecked: false
    });
  };
  
  handleMouseOver = () => {
    this.setState({ isHovered: true });
  };
  handlecloseDialog = () => {
    this.setState({ isOpenDialog: false });
  };
  handleMouseOut = () => {
    this.setState({ isHovered: false });
  };
  handleChanxgeAutomatic = (event: any) => {
    this.setState({ automaticChecked: event.target?.checked })
    if(this.state.isEditkpi && event.target?.checked ){ this.setState({goalType:'number'})}
  }
  handleCalMethods = (event: any) => {
    this.setState({ goalCalMethod: event.target.value });
  }
  handleChangeChecked2 = (event: any) => {
    this.setState({ analyticsChecked: event.target?.checked })
    this.getAnalyticaldetails()
  }
  handleChangeCheckedRunRate=(event: any)=>{
    this.setState({  runRateChecked: event.target?.checked })
  }

  handleGoalTypeChange = (event: any) => {
    if(event.target.value==="number"){
      this.setState({goalValue:0})
    }
    else{
      this.setState({goalValue:100})
    }
    this.setState({ goalType: event.target.value, goalValError:"" });
    if(event.target.value === 'percentage'
    ){this.setState({goalValue:100})}
  };
  handleAnalyaticParam = (event: any) => {
    this.setState({ analyticParam: event.target.value});
  }
  getTemplateAddedDetail = async (responseJson: any) => {
    const kpiNumbers = (responseJson.grouped_templates?.Core?.map((item: any) => parseInt(item.name.split('-')[1]))) || 0;
    const nextKpiNumber = kpiNumbers.length > 0 ? Math.max(...kpiNumbers) + 1 : 1;
    this.setState({ kpiNextNo: nextKpiNumber })
  }
  handleFocus = () => {
    this.setState({kpiWeightError: ''});
  };
  handleNumberInput = (event:any) => {
    if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
    }
};


  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {selectedOption, kpiNextNo, isEditkpi, kpiNameUpdate} = this.state;
    const { name, value } = event.target;
    let kpiNameUpdates = this.state.kpiNameUpdate;
    if (['core', 'attendance', 'timing', 'report'].includes(selectedOption)) {
      switch (selectedOption) {
        case 'core':
          kpiNameUpdates = isEditkpi? `${kpiNameUpdate}` : `KPI-${kpiNextNo}`;;
          break;
        case 'attendance':
          kpiNameUpdates = 'Attendance';
          break;
        case 'timing':
          kpiNameUpdates = 'Timing';
          break;
        case 'report':
          kpiNameUpdates = 'Report';
          break;
        default:
          kpiNameUpdates = '';
      }
    } else if (name === 'kpiNameUpdate') {
      kpiNameUpdates = value;
    }
    this.setState({kpiNameUpdate: kpiNameUpdates})
    
    if (name === 'descriptions') {
      this.setState({descriptionsLenght: value.length});
    }
    this.setState({
      [name]: value
    } as Pick<any, keyof any>);
  };
  handleChangeGoalVal = (event: any) => {
  const value = parseInt(event.target.value);
  
  if (value <= 0) {
    this.setState({ 
      goalValue: event.target.value,
      goalValError: "Goal value can't be negative or Zero"
    });
  } else {
    this.setState({ 
      goalValue: event.target.value,
      goalValError: ""
    });
}
  }
  handleNavigationBack = () => {
    this.props.navigation.navigate("EditTemplate2")
  }

  handleEditkpi = () => {
    const {kpiData} = this.state
    
    this.setState({selectedOption: kpiData.attributes.kpi_type, 
      kpiNameUpdate: kpiData.attributes.name,
      descriptions: kpiData.attributes.description,
      points: kpiData.attributes.weightage, 
      automaticChecked: kpiData.attributes.automatic_calculation,
      goalType: kpiData.attributes.goal_type,
      goalCalMethod: kpiData.attributes.calculation_method,
      goalValue: kpiData.attributes.goal_value,
      runRate: kpiData.attributes.run_rate,
      analyticsChecked: kpiData.attributes.part_of_analytics,
      analyticParam: kpiData.attributes.analytics_parameter_id
    })
    
  }
  handleSubmit = () => {
    const { kpiNameUpdate, descriptions, points, selectedOption, goalType, goalValue, automaticChecked} = this.state;   
    console.log("checkkkkkkkk", goalValue, selectedOption);
    if (kpiNameUpdate.length == 0 && selectedOption !== 'core' && selectedOption !== 'attendance' && selectedOption !== 'timing' && selectedOption !== 'report') {
      this.setState({ kpinameError: "Title is required" });
    } else if (!descriptions) {
      this.setState({ kpiDescrError: "Description is required" });
    } else if (!points ) {
      this.setState({ kpiWeightError: "Points are required" });
    } else if (Number(points) <= 0) {
      this.setState({ kpiWeightError: "Points can't be negative" });
    } else if (automaticChecked && goalValue === 0) {
      this.setState({ goalValError: "Goal value can't be negative or Zero" });
    } else if ((automaticChecked && !goalValue) || (selectedOption === 'productivity' && !goalValue)) {
      this.setState({ goalValError: "Goal value is required" });
    } else if (automaticChecked && goalType === "percentage" && goalValue > 100) {
      this.setState({ goalValError: "Percentage should not more than 100" });
    } else{      
      this.createNewKpi()
    }
  };

  handleCreatekpiRes = async (responseJson: any) => {
    if (responseJson?.error) {
      this.setState({dialogMessage: responseJson.error, isOpenDialog: true})
    } else {
      this.props.navigation.navigate("EditTemplate2")
    }
  }
  handleOpenSecond = (modalTitle: any, eventId: any, description: any) => {
    if (!eventId) {
      this.setState({ open: true, infoDescription: description, infoTitle: modalTitle })
    }
    const filteredData = eventId
      ? this.state.employeesData.filter(
        (item: any) => item.attributes.id === eventId
      )
      : [];
    const formatDate = (date: any) => {
      const parsedDate = new Date(date);
      const day = String(parsedDate.getDate()).padStart(2, '0');
      const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
      const year = parsedDate.getFullYear();
      return `${day}-${month}-${year}`;
    };
    const filteredItem = filteredData.length > 0 ? filteredData[0].attributes : null;
    const formattedStartDate = filteredItem ? formatDate(filteredItem.start_date) : null;
    const formattedEndDate = filteredItem ? formatDate(filteredItem.end_date) : null;
    const newModalTitle = filteredItem.event_name;
    const newDescription = filteredItem.description
    const dayType = filteredItem.day_type === 'full_day' ? 'Full Day' : 'Half Day'
    this.setState({
      title: newModalTitle,
      descriptionModalSecond: newDescription,
      openNew: true,
      filteredData,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      dayType,
    });
  };

  handleRemarkSubmit = () => {
    const { dkrRemark, dkrCommentData, dkrId } = this.state;
    const existingComment = dkrCommentData?.find(comment => comment.commentable_id === dkrId);
    if (existingComment) {
      this.updateCommentData(existingComment.dkr_input, dkrId, dkrRemark);
    }
    this.setState({ isModal: false });
  };
  
  newHandleClose=()=>{
    this.setState({ isModal: false })
  }
  newHandleClick=( dkrName: string, dkrId : number)=>{
      this.setState({ isModal: true , dkrName : dkrName, dkrId : dkrId })
  }
  handleCloseNew = () => {
    this.setState({ openNew: false, open: false })
  }
  Goback = async (path: any) => {
    this.setState({ statisticsTab: "" })
    removeStorageData("tabb")
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  }
  handleBack = async (path: any) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  }
  
  handleCancel = async (path: any) => {
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), path);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  }
  
  getEventDetails = async () => {
    let userToken = window.localStorage.getItem("authToken");
    const fetchactvateAccountsHeader = {
      token: userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getEventDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_gamification/events/company_events`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchactvateAccountsHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  infoboardsDetails = async () => {
    let companyId = await getStorageData("companyId");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getinfoboardsDetailsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/infoboards?company_id=${companyId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getAnalyticaldetails = async () => {
    let userToken = window.localStorage.getItem("authToken");
    const fetchactvateAccountsHeader = {
      token: userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAnalyticsDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_gamification/organization_templates/analytics_dropdown`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchactvateAccountsHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTemplateAddedResponse = async () => {
    let userToken = window.localStorage.getItem("authToken");
    const templateId = await getStorageData("templateId")
    const fetchTemplateAddedHeader = {
      "Content-Type": configJSON.dashboarContentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTemplateDataId = requestMessage.messageId;
    const endpoint = `bx_block_gamification/kpi_listing?template_type=organization_templates&template_id=${templateId}`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchTemplateAddedHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  createNewKpi = async () => {
    let userToken = window.localStorage.getItem("authToken");
    const templateId = await getStorageData("templateId");
    const templateEditId = localStorage.getItem('TemplateNavigationId');
    const getValue = (value:any, defaultValue = '') => value !== null && value !== undefined ? String(value) : defaultValue;
    const {
      selectedOption,
      kpiNameUpdate,
      descriptions,
      points,
      automaticChecked,
      analyticsChecked,
      goalType,
      goalValue,
      goalCalMethod,
      analyticParam,
      runRate,
      isEditkpi
    } = this.state;
    const endpoint = isEditkpi? `bx_block_gamification/kpis/${templateEditId}` : `bx_block_gamification/kpis`;
    const formData = new FormData();

    formData.append('organization_template[kpi_type]', selectedOption);
    formData.append('organization_template[organization_template_id]', templateId);
    formData.append('organization_template[name]', kpiNameUpdate);
    formData.append('organization_template[weightage]', points);
    formData.append('organization_template[description]', descriptions);
    formData.append('organization_template[automatic_calculation]', String(automaticChecked));
    formData.append('organization_template[goal_value]', getValue(goalValue));
    formData.append('organization_template[goal_type]', automaticChecked ? getValue(goalType) : '');
    formData.append('organization_template[part_of_analytics]', automaticChecked ||selectedOption === 'productivity' ? getValue(analyticsChecked) : '');
    formData.append('organization_template[calculation_method]', automaticChecked && goalType ? getValue(goalCalMethod, 'sum_calculation') : ' ');
    formData.append('organization_template[run_rate]', automaticChecked ||selectedOption === 'productivity' ? getValue(runRate, 'false') : 'false');
    formData.append('organization_template[analytics_parameter_id]', analyticsChecked ||selectedOption === 'productivity' ? getValue(analyticParam, '3') : '');

    const fetchTemplateAddedHeader = {
      "Content-Type": configJSON.dashboarContentType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postNewKpiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(fetchTemplateAddedHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      isEditkpi? configJSON.exampleAPiUpdate : configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  showTemplateswithKpis = async (id:number) => {
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token:localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getShowTempaltewithKpiId= requestMessage.messageId;
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     
     `bx_block_gamification/kpis/${id}`
    );
   requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDkrInitialValue = () =>{
    const {kpisDkrData , dkrCommentData} = this.state;
    const filteredKpisDkrData = kpisDkrData.filter(item => item.kpi_type !== "checklist");
    {filteredKpisDkrData.forEach((item) =>{
      const dKR_Title = item.options?.flat();
    
    dKR_Title?.forEach((item)=>{
      const newComment = {
        commentable_type: "BxBlockGamification::ApplicableDkr",
        commentable_id: item.id ? item.id : item.dkr_id,
        dkr_input: "",
        remark: "",
        account_id: this.state.accountId
      };
      dkrCommentData.push(newComment);
    })
    }
  )}
  }

  handleDkrInput = (event: { target: { value: string } }, optionId: number) => {
    const dkrValue = event.target.value;  
    this.setState({ dkrValue: dkrValue}, () => {
      this.updateCommentData(dkrValue, optionId);
    });
  };

  handleRemarkValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dkrRemark = event.target.value;
    this.setState({ dkrRemark: dkrRemark})
  }
  
  updateCommentData = (dkrValue: string, optionId: number, remark: string = "") => {
    const newComment = {
      commentable_type: "BxBlockGamification::ApplicableDkr",
      commentable_id: optionId,
      dkr_input: dkrValue,
      remark: remark,
      account_id: this.state.accountId
    };
  
    this.setState((prevState) => {
      const existingComments = prevState.dkrCommentData || []; 
      const commentIndex = existingComments.findIndex(comment => comment.commentable_id === optionId);
      if (commentIndex !== -1) {
        existingComments[commentIndex] = newComment;
      } else {
        existingComments.push(newComment);
      }
      return { dkrCommentData: existingComments };
    });
  };
  
  
  handleKpiDKRData = async () => {
    this.setState({loading : true})
    this.getKpiDKRDataApiCallId = await this.apiCallDKR({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getKpiGamecardApiEndPoint,
    });
  };

  getKpiDKRDataSuccessCallBack = (responseJson: KpiDKRData) => {
    if(responseJson){
      const kpis = responseJson.data.attributes.kpis
      const allKpis = Object.values(kpis).flat();
      const kpiData : Array<KpisDKR> = []
      allKpis.forEach((item : KpisDKR) =>{
        if(item.kpi_type !== "timing" && item.kpi_type !== "attendance" && item.kpi_type !== "report" ){
          kpiData.push(item)
        } 
      })
      if(kpiData){
        this.setState({ kpisDkrData: kpiData , loading: false},()=>{
          this.handleDkrInitialValue()
        })
      }
    }
  }

  getKpiDKRDataFailurCallBack = (responseJson: InvalidResponseType) => {
    this.setState({ hasError: responseJson.errors[0], loading: false })
    if(responseJson.errors[0]){
      this.setState({ loading: false })
      ToastNotification.showError(responseJson.errors[0]);
    }
  }

  validateInputs = () => {
    const { dkrCommentData } = this.state;

    if (!dkrCommentData || dkrCommentData.length === 0) {
      return { isValid: false, errorMessage: "Please ensure all DKR inputs are filled." };
  }

    for (const comment of dkrCommentData) {
      if (!comment.dkr_input || comment.dkr_input.trim() === "") {
        return { isValid: false, errorMessage: "Please ensure all DKR inputs are filled." };
      }
    }
    return { isValid: true, errorMessage: "" };
  };
  

  savetKpiDKRData = async () => {
    const validation = this.validateInputs();  
    if (!validation.isValid) {
      ToastNotification.showError(validation.errorMessage);
      return;
    }

    let apiBody = {
      comments: this.state.dkrCommentData
  };
    this.postKpiDKRDataApiCallId = await this.apiCallDKR({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.postKpiDkrApiEndPoint,
      body: JSON.stringify(apiBody)
    });
  };

  saveKpiDKRDataSuccessCallBack = (responseJson: SaveDkrData) => {
   if(responseJson){
    ToastNotification.showSuccess("DKRs submitted successfully.");
      this.setState({ dkrCommentData: [] });
      this.handleBack("EmployeeDashboard");
    }
  }

  saveKpiDKRDataFailurCallBack = (responseJson: InvalidResponseType) => {
   if(responseJson.errors){
    ToastNotification.showError(responseJson.errors[0]);
   }
  }
  // Customizable Area End
}
