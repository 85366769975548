import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import toast from "react-hot-toast";
import { getStorageData } from "../../../framework/src/Utilities";
import ToastNotification from "../../../components/src/ToastNotification.web";
import moment from "moment";

export interface ValidResponseType {
  data: object;
  errors: Array<ErrorPayloadType>;
}
export interface ErrorPayloadType {
  key: string;
  message: string;
}
export interface ErrorResponseType {
  errors: string;
}
export interface InvalidResponseType {
  errors: Array<string>;
}

export interface KpiDkrDetailsData {
  id: string,
  type: string,
  attributes: {
    id: number,
    name: string,
    title: string,
    description: string,
    status: string,
    department_id: number,
    kpis: KpisDkrDetails
  }
}

export interface KpiDkrData {
  data: KpiDkrDetailsData
}

export interface KpisDkrDetails {
  Core: Array<DkrDetailsData>,
  Productivity: Array<DkrDetailsData>,
  Checklist: Array<DkrDetailsData>,
  Attendance: Array<DkrDetailsData>,
  Timing: Array<DkrDetailsData>,
  Occurrence: Array<DkrDetailsData>  
  Report: Array<DkrDetailsData>
}

export interface DkrDetailsData {
  kpi_id: number,
  kpi_desc: string,
  KPI: string,
  kpi_type: string,
  weightage: number,
  goal_value: number,
  goal_type: string,
  run_rate: boolean,
  DKR_Title: Array<string>,
  DKR_Title_value: Array<string>,
  options: Array<ChecklistOption>,
  DKR_description: null,
  DKR_response_type: Array<string>,
  calculation_type: string,
  minimum_qualify_value: string,
  applicable_occurrences: Array<string>,
  app_occu_count: number
}

export interface ChecklistOption {
  id: number,
  title: string
}

export interface CurrentProgressData {
  total_dkr_input: number,
  goal_value: number,
  current_run_rate: number,
  required_run_rate: number,
  percentage_achieved: number
}

export interface MonthWiseData {
  data: Array<MonthWiseComment>,
  largest_dkr_input: number,
  largest_dkr_created_at: string
}

export interface MonthWiseComment {
  comments: Array<MonthWiseCommentData>
}

export interface MonthWiseCommentData {
  date: string,
  comments: Array<CommentsData>
}

export interface CommentsData {  
    dkr_input: string,
    created_at: string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Row {
  id: number;
  title: string;
  description: string;
}
export interface CalculationRow {
  id: string;
  min: number;
  max: number;
  weightage:number
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: string
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  type: string;
  description: string;
  automatic: boolean;
  runRate: boolean;
  analytics: boolean;
  goaltype: string;
  tableRow:Row[];
  calculationType:CalculationRow[];
  offset:number;
  progressPercentage: number;
  chartData:any;
  storedLabel:string;
  checklistsOptions:any;
  checked:boolean;
  open:boolean;
  pointsAchieved:number;
  newPointsAchieved:number;
  submissions:any[];
  toggle:boolean;
  dkrDetailsData:Array<DkrDetailsData>;
  dkrDetail: DkrDetailsData;
  loading: boolean;
  currentProgress: CurrentProgressData;
  monthWiseData: MonthWiseData;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AddDkrDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDkrDetailDataApiCallId: string | null = null;
  getCurrentProgressApiCallId: string | null = null;
  getMonthWiseDetailApiCallId: string | null = null;
  getProgressDataApiCallId: string | null = null;
  getCurrentDataApiCallId: string | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      type: "prorata",
      description: "",
      automatic: false,
      runRate: false,
      analytics: false,
      goaltype: "number",
      storedLabel:'',
      open:false,
      pointsAchieved:120,
      newPointsAchieved:0,
      tableRow: [
        { id: 1, title: "Captured DKR", description: "Today's Sales" },
        { id: 2, title: "Gamified DKR", description: "Total Sales" }
      ],
      calculationType:[
        { id: "1", min: 0, max: 90,weightage:2 },
        { id: "2", min: 90.1, max: 100,weightage:8 }

      ],
      offset: 2 * Math.PI * 50, 
      progressPercentage: 50,
      chartData:{
        series: [
          {
            name: 'Goal',
            data: [],
          },
        ],
        options: {
          chart: {
            type: 'area',
            height: 350,
            toolbar: {
              show: false,
            },
          },
          colors: ['#FFCC00'],
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.4,
              opacityTo: 0.4,
              stops: [0, 100],
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
            width: 2,
            colors: ['#FFCC00'],
          },
          xaxis: {
            categories: Array.from({ length: 16 }, (_, i) => i * 2 + 1).slice(0, 32),
            title: {
              text: 'Days of Month',
            },
          },
          yaxis: {
            title: {
              text: 'Goal',
            },
            labels: {
              formatter: function (value:any) {
                return value / 1000 + 'k';
              },
            },
            tickAmount: 4,
            min: 0,
            max: 4000,
          },
          grid: {
            borderColor: '#e7e7e7',
          },
        },
      },
      checklistsOptions : [
        { name: 'Vision Posted'},
        { name: 'Core Values Posted'},
        { name: 'Daily Pledge'},
        { name: 'P&L / SET Done',},
        { name: 'Budget Tracking'},
      ],
      checked:false,
      submissions : [
        { date: "01-03-2024", report: "Submitted", attendance: "Present", timing: "On Time" },
        { date: "02-03-2024", report: "Submitted", attendance: "Present", timing: "On Time" },
        { date: "03-03-2024", report: "Missed", attendance: "Absent", timing: "Late" },
        { date: "04-03-2024", report: "Submitted", attendance: "Present", timing: "On Time" },
        { date: "05-03-2024", report: "Missed", attendance: "Absent", timing: "Late" },
        { date: "06-03-2024", report: "Submitted", attendance: "Present", timing: "On Time" },
    ],
    toggle:false,
    dkrDetailsData: [],
    dkrDetail: {} as DkrDetailsData,
    loading: false,
    currentProgress: {} as CurrentProgressData,
    monthWiseData: {} as MonthWiseData,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const storedLabel = await getStorageData('selectedLabel');
    if (storedLabel) {
      this.setState({ storedLabel });
    }
    const randomProgress = 92
    this.setProgress(randomProgress);
    this.handleDkrDetailData();
    this.handleCurrentProgress();
    this.handleMonthWiseDetail();
    this.handleCurrentData();
    this.handleProgressData();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.errors) {
          let message: string;
          const error = responseJson.errors[0];

          if (typeof error === 'string') {
            message = error;
            toast.error(message);
          }
        }
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isValidResponseController(responseJson)) {
        this.apiSuccessCallBacksController(apiRequestCallId, responseJson);
      }
      if (this.isInValidResponseController(responseJson)) {
        this.apiFailureCallBacksController(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // web events
  handleChange = (event: React.ChangeEvent<{
    name?: string | undefined;
    value: string;
  }>) => {
    this.setState({
      type: event.target.value
    });
  };
  handleAddItem = () => {
    const newItem = { id: '', min: 0, max: 0, weightage: 0 }; // You can adjust the initial values as needed
    this.setState(prevState => ({
      calculationType: [...prevState.calculationType, newItem]
    }));
  };

  handleRemoveItem = (index:number) => {
    this.setState(prevState => ({
      calculationType: prevState.calculationType.filter((_, i) => i !== index)
    }));
  };
 
  // Customizable Area Start
  isValidResponseController = (responseJson: ValidResponseType) => {
    return responseJson && !responseJson.errors;
  };

  isInValidResponseController = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiSuccessCallBacksController = (apiRequestCallId: string, responseJson: KpiDkrData & CurrentProgressData & MonthWiseData) => {
    if (apiRequestCallId === this.getDkrDetailDataApiCallId) {
      this.getDkrDetailDataSuccessCallBack(responseJson)
    }
    if (apiRequestCallId === this.getCurrentProgressApiCallId) {
      this.getCurrentProgressSuccessCallBack(responseJson)
    }
    if (apiRequestCallId === this.getMonthWiseDetailApiCallId) {
      this.getMonthWiseDetailSuccessCallBack(responseJson)
    }
    if (apiRequestCallId === this.getCurrentDataApiCallId) {
      this.getCurrentDataSuccessCallBack(responseJson)
    }
    if (apiRequestCallId === this.getProgressDataApiCallId) {
      this.getProgressDataSuccessCallBack(responseJson)
    }
  };

  apiFailureCallBacksController = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
    if (apiRequestCallId === this.getDkrDetailDataApiCallId) {
      this.getDkrDetailDataFailurCallBack(responseJson)
     }
    if (apiRequestCallId === this.getCurrentProgressApiCallId) {
      this.getCurrentProgressFailurCallBack(responseJson)
    }
    if (apiRequestCallId === this.getMonthWiseDetailApiCallId) {
      this.getMonthWiseDetailFailurCallBack(responseJson)
    }
    if (apiRequestCallId === this.getCurrentDataApiCallId) {
      this.getCurrentDataFailurCallBack(responseJson)
    }
    if (apiRequestCallId === this.getProgressDataApiCallId) {
      this.getProgressDataFailurCallBack(responseJson)
    }
   };
 
  dkrDetailsApiCall = async (valueData: {
    method?: string;
    contentType?: string;
    body?: {};
    endPoint?: string;
    type?: string;
    apiType?: string;
   }) => {
     let { contentType, method, endPoint, body} = valueData;
     const token = (await getStorageData("authToken")) || "";
     let header = {
        "Content-Type": contentType,
        token,
     };
      let requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
     body &&
       requestMessage.addData(
         getName(MessageEnum.RestAPIRequestBodyMessage),
         body
       );
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
     );
     runEngine.sendMessage(requestMessage.id, requestMessage);
     return requestMessage.messageId;
   };

  setProgress(progress :any) {
    const radius = 50;
    const circumference = 2 * Math.PI * radius;
    const progressOffset = ((100 - progress) / 100) * circumference;
    this.setState({ offset: progressOffset, progressPercentage: progress });
  }


  backNavigate =(pathChange:any)=>{
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pathChange);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  handleChangeChecbox =(event:any)=>{
    this.setState(event.target.checked);
  }
  handleOpen = () => {
    this.setState({  open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  
 
  handleInputChange = (event:any)=>{
  const inputValue=event?.target.value
  this.setState({newPointsAchieved:inputValue})
  }
  handleCloseSubmit = () => {
    this.setState({ open: false });
    this.setState({pointsAchieved:this.state.newPointsAchieved})
  };
  
  GoToDailySubmissions =(pathChange:any)=>{
    const toNavigate: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    toNavigate.addData(getName(MessageEnum.NavigationTargetMessage), pathChange);
    toNavigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toNavigate);
  };

  handleDkrDetail = async () => {
    const kpiId = await getStorageData("selectedLabel");
    const { dkrDetailsData } = this.state;
    const dkrDetail = dkrDetailsData.find(item => item.kpi_id === Number(kpiId));
    if (dkrDetail) {
      this.setState({ dkrDetail: dkrDetail });
    }
  };
   
  handleDkrDetailData = async () => {
     this.setState({loading: true})
     this.getDkrDetailDataApiCallId = await this.dkrDetailsApiCall({
       contentType: configJSON.validationApiContentType,
       method: configJSON.validationApiMethodType,
       endPoint: configJSON.getKpiGamecardApiEndPoint,
     });
   };

  getDkrDetailDataSuccessCallBack = (responseJson: KpiDkrData) => {
     if(responseJson){
       const kpis = responseJson.data.attributes.kpis
       const allKpis = Object.values(kpis).flat();
       this.setState({ dkrDetailsData: allKpis, loading: false},
            ()=> this.handleDkrDetail())
     }
  }

  getDkrDetailDataFailurCallBack = (responseJson: InvalidResponseType) => {
     const responses = responseJson.errors;
     if(responses){
       ToastNotification.showError(responseJson.errors[0]);
       this.setState({ loading: false})
     }
   }

  handleCurrentProgress = async () =>{
     const kpiId = await getStorageData("selectedLabel");
     this.getCurrentProgressApiCallId = await this.dkrDetailsApiCall({
       contentType: configJSON.validationApiContentType,
       method: configJSON.validationApiMethodType,
       endPoint: `${configJSON.getCurrentProgressApiEndPoint}?kpi_id=${kpiId}`,
     });
   };

  getCurrentProgressSuccessCallBack = (responseJson: CurrentProgressData) =>{
     if(responseJson){
       this.setState({currentProgress : responseJson})
     }
   }

  getCurrentProgressFailurCallBack = (responseJson: InvalidResponseType) =>{
     const responseError = responseJson.errors;
     if(responseError){
       ToastNotification.showError(responseJson.errors[0]);
     }
   }

  handleCurrentData = async () =>{
       const kpisId = await getStorageData("selectedLabel");
      this.getCurrentDataApiCallId = await this.dkrDetailsApiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: `${configJSON.getCurrentProgressApiEndPoint}?kpi_id=${kpisId}`,
      });
   };

  getCurrentDataSuccessCallBack = (responseJson: CurrentProgressData) =>{
      if(responseJson){
         this.setState({currentProgress : responseJson})
       }
    }

  getCurrentDataFailurCallBack = (responseJson: InvalidResponseType) =>{
       const responseError = responseJson.errors;
       if(responseError){
        ToastNotification.showError(responseJson.errors[0]);
       }
   }

  handleProgressData = async () =>{
     const kpIId = await getStorageData("selectedLabel");
     this.getProgressDataApiCallId = await this.dkrDetailsApiCall({
          contentType: configJSON.validationApiContentType,
          method: configJSON.validationApiMethodType,
           endPoint: `${configJSON.getCurrentProgressApiEndPoint}?kpi_id=${kpIId}`,
     });
   };

  getProgressDataSuccessCallBack = (responseJson: CurrentProgressData) =>{
      if(responseJson){
          this.setState({currentProgress : responseJson})
      }
   }

  getProgressDataFailurCallBack = (responseJson: InvalidResponseType) =>{
      const responseErrors = responseJson.errors;
      if(responseErrors){
          ToastNotification.showError(responseJson.errors[0]);
      }
   }

  handleMonthWiseDetail = async () => {
    const kpiId = await getStorageData("selectedLabel");
    const currentMonth = moment().format('M');
    const currentYear = moment().format('YYYY');
    this.getMonthWiseDetailApiCallId = await this.dkrDetailsApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getMonthWiseDetailApiEndPoint}?kpi_id=${kpiId}&month=${currentMonth}&year=${currentYear}`,
    });
  };

  getMonthWiseDetailSuccessCallBack = (responseJson: MonthWiseData) => {   
    if(responseJson){
      this.setState({monthWiseData : responseJson})
      const apiData = responseJson.data;
      const goalData = apiData.map((item) => {
        if (item.comments.length > 0) {
          return parseInt(item.comments[0].comments[0].dkr_input, 10) || 0;
        }
      });
  
      this.setState((prevState) => ({
        chartData: {
          ...prevState.chartData,
          series: [{ ...prevState.chartData.series[0], data: goalData }],
        },
      }));
    }
  } 

  getMonthWiseDetailFailurCallBack = (responseJson: InvalidResponseType) => {
    const response = responseJson.errors;
    if(response){
      ToastNotification.showError(responseJson.errors[0]);
    }
  }
  // Customizable Area End
}
